<template>
  <transition name="fade">
    <div class="response-search">
      <div class="response-search__wrapper">
        <Section limiter>
          <Row justify="between">
            <Column class="grid-md-2 grid-lg-2">
              <span class="response-search__result">{{ $t('service.search_results') }}</span>
            </Column>
            <Column class="grid-md-10 grid-lg-10">
              <Column class="response-search__list"
                      v-if="$search.data.length > 0">
                <router-link :to="item.link.replace('/content', '')"
                             class="row response-search__list-item"
                             v-for="item in $search.data"
                             :key="item.uinqid">
                  <Column v-if="item.images[0]">
                    <div class="response-search__list-item-image-holder">
                      <img class="response-search__list-item-image"
                           :src="item.images[0]"
                           alt/>
                    </div>
                  </Column>
                  <Column class="grid-md-6 grid-lg-6">
                    <p class="response-search__list-item-type"
                       v-if="item.type">
                      {{$type(item.type.name ? item.type.name : item.type)}}</p>
                    <p class="response-search__list-item-title">{{item.title}}</p>
                    <p class="response-search__list-item-description">{{item.description}}</p>
                  </Column>
                </router-link>
                <Button v-if="$search.links.next"
                        v-on:click.native="nextPage"
                        class="trips-view__more"
                        color="like-link">{{ $t('service.show_more') }}
                </Button>
              </Column>
              <span class="response-search__not-found"
                    v-else>{{ $t('service.not_found') }}</span>
            </Column>
          </Row>
        </Section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ResponseForSearch',
  methods: {
    nextPage() {
      this.$store.dispatch('GET_NEXT_REQUEST_SEARCH_FROM_SERVER', {
        params: {
          page: this.$search.links.next,
          count: 6,
          lang: this.$i18n.locale,
        },
      });
    },
  },
};
</script>
